<script>
export default {
  props: {
    storeStateComponent: {
      type: String,
      required: true,
    },
    currencyModel: {
      type: String,
      required: true,
    },
    isNotFreelance: {
      type: Boolean,
      required: false,
    },
    mode: {
      type: String,
      required: false,
    },
    idRegistro: {
      type: String,
      required: false,
    },
    items: {
      type: Array,
      required: false,
    },
    revisionPeriodicaVehiculo: {
      type: Array,
      required: false,
    },
    data: {
      validator: function (value) {
        // Validación personalizada para data
        return Array.isArray(value) || typeof value === "object";
      },
    },
  },
  data() {
    return {
      dataPatch: {},
      alert: {
        isSaved: null,
        message: "",
        variant: "success",
      },
      updateData: {
        id: "00000000-0000-0000-0000-000000000000",
        vehiculoId: "00000000-0000-0000-0000-000000000000",
        fechaRegistro: "0001-01-01T00:00:00",
        fechaFinalizacion: "0001-01-01T00:00:00",
        kilometraje: 0,
        tipoInspeccion: null,
        tarjetaPropiedad: null,
        seguroObligatorio: null,
        revisionTecnomecanica: null,
        polizaContractual: null,
        observacionesDocumento: null,
        aseoGeneral: null,
        accesoPisosSinObstaculos: null,
        almacenamientoHerramienta: null,
        almacenamientoRepuestos: null,
        cabinaOrdenadaSinObjetosSuelo: null,
        observacionesOrdenAseo: null,
        llantaRepuesto: null,
        estadoLlantas: null,
        rines: null,
        bomperTrasero: null,
        bomperDelantero: null,
        stop: null,
        placa: null,
        guardaPolvos: null,
        emblemas: null,
        manijas: null,
        barraAntivuelco: null,
        cubrePlaton: null,
        vidrios: null,
        espejos: null,
        lameVidrio: null,
        cocuyos: null,
        plumillas: null,
        mionas: null,
        antena: null,
        direccionales: null,
        luces: null,
        ganchosRemolque: null,
        llavero: null,
        observacionesCarroceria: null,
        sillaConductor: null,
        sillaCopiloto: null,
        sillaPasajeros: null,
        forros: null,
        volante: null,
        tableroInstrumentos: null,
        espejoRetrovisor: null,
        reloj: null,
        funcionPito: null,
        tapetes: null,
        manijaFrenoDeMano: null,
        botonesAc: null,
        radio: null,
        controlRadio: null,
        parlantes: null,
        elevavidrios: null,
        switchEspejos: null,
        switchLuces: null,
        viseras: null,
        cinturonesSeguridad: null,
        manijasInternas: null,
        encendedor: null,
        cenicero: null,
        observacionesCabina: null,
        bateria: null,
        filtroTrampa: null,
        posicionCorreas: null,
        bayoneta: null,
        tapas: null,
        conexionesCables: null,
        niveles: null,
        observacionesMotor: null,
        gato: null,
        palancaGato: null,
        conos: null,
        hombreSolo: null,
        alicate: null,
        llavesBocaFija: null,
        cablesInicio: null,
        botiquin: null,
        extintor: null,
        llaveAlemana: null,
        llavePerno: null,
        tacosPlasticos: null,
        calibradorPresion: null,
        lamparaLinterna: null,
        destornilladorMixto: null,
        llavesBristol: null,
        manila12m: null,
        otras: null,
        observacionesHerramientas: null,
        palaAntichispasEscobilla: null,
        telaOleofilicaPanosAbsorbentes: null,
        bolsasRojas: null,
        cintaDeSeguridad: null,
        guantesDeNitrilo: null,
        proteccionRespiratoria: null,
        observacionesElementosSeguridad: null,
      },
    };
  },
  async mounted() {
    console.log(this.modelStore);
    console.log("aloneRevision", this.data);

    if (this.mode == "create") {
      this.modelStore.forEach((item) => {
        if (item.name == "kilometraje") {
          item.value = 0;
        }
        item.value = null;
      });
    }

    if (this.mode == "update") {
      // Asegúrate de que updateData esté inicializado y contenga los datos
      if (this.updateData) {
        await this.getData(this.data.id);
        this.modelStore.forEach((item) => {
          for (const key in this.updateData) {
            if (item.name == key) {
              // Asigna el valor correspondiente desde updateData
              if (
                this.updateData[key] !== null &&
                this.updateData[key] !== undefined &&
                this.updateData[key] !== 0
              ) {
                if (typeof this.updateData[key] === "string") {
                  item.value = this.updateData[key].toLowerCase();
                } else {
                  // Aquí puedes manejar el caso en que no es una cadena.
                  item.value = this.updateData[key];
                }
              } else {
                if (item.name == "kilometraje") {
                  item.value = 0;
                }
                // Manejar el caso en que el valor es null o undefined.
                item.value = null;
              }

              console.log(
                "valor asignadooooooooooooooo",
                item.value,
                this.updateData[key],
                key
              );
            }
          }
        });
      } else {
        console.log("updateData está vacío o no está definido.");
      }
    }
  },

  methods: {
    async getData(id) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "RevisionPeriodicaVehiculo/Alone/" + id,
        });
        console.log("aloneRevision dataaa...", res);
        if (res.id) {
          this.updateData = res;
        }
      } catch (error) {
        console.log("err", error);
        return null;
      }
    },
    capitalize(texto) {
      if (texto != null) {
        return texto.charAt(0).toUpperCase() + texto.slice(1);
      }
      return null;
    },
    toCamelCase(texto) {
      if (texto != null) {
        return texto.charAt(0).toLowerCase() + texto.slice(1);
      }
      return null;
    },
    async onSubmitComponent() {
      let model = this.modelStore;
      for (const item of model) {
        if (!item.value) {
          continue; // Saltar los campos vacíos
        }

        if (item.type == "select" || item.type == "textarea") {
          this.dataPatch[this.capitalize(item.name)] = this.capitalize(
            item.value
          );
        }

        if (item.type == "number") {
          this.dataPatch[this.capitalize(item.name)] = Number(item.value);
        }
      }

      console.log("dataPatch---------------->", {
        ...this.dataPatch,
        Kilometraje: this.dataPatch.Kilometraje
          ? this.dataPatch.Kilometraje
          : this.data.kilometraje,
      });

      let res = await this.PatchData(this.idRegistro, {
        ...this.dataPatch,
        Kilometraje: this.dataPatch.Kilometraje
          ? this.dataPatch.Kilometraje
          : this.data.kilometraje,
      });

      if (res) {
        for (const key in this.dataPatch) {
          const newKey = this.toCamelCase(key);
          this.data[newKey] = this.dataPatch[key];
        }
        console.log("dataaaaaaaaaa", { ...this.data });
      }
    },
    async PatchData(id, data) {
      try {
        let res = await this.$store.dispatch("patch", {
          path: `RevisionPeriodicaVehiculo/${id}`,
          data,
        });
        console.log("ressssss------>", res);
        if (res) {
          this.alert.isSaved = true;
        }
        return res;
      } catch (error) {
        console.log(error);
        this.alert.isSaved = false;
        this.alert.variant = "danger";
      }
    },
  },
  computed: {
    modelStore() {
      return this.$store.state[this.storeStateComponent][this.currencyModel];
    },
  },
};
</script>

<template>
  <div>
    <b-form class="mb-4">
      <div class="row">
        <b-form-group
          class="col-12 col-lg-4"
          v-for="item in modelStore"
          :key="item.name"
          :label="item.title"
          :label-for="`${currencyModel}-${item.name}`"
        >
          <b-form-input
            v-if="
              item.type === 'text' ||
              item.type === 'number' ||
              item.type === 'date' ||
              item.type === 'time'
            "
            v-model="item.value"
            :id="`${currencyModel}-${item.name}`"
            :type="item.type"
            :placeholder="item.placeholder"
          ></b-form-input>

          <b-form-select
            v-if="item.type === 'select'"
            v-model="item.value"
            :id="`${currencyModel}-${item.name}`"
            :options="item.options"
            :placeholder="item.placeholder"
          ></b-form-select>

          <b-form-textarea
            v-if="item.type === 'textarea'"
            :id="`${currencyModel}-${item.name}`"
            v-model="item.value"
            rows="3"
            :placeholder="item.placeholder"
            required
          >
          </b-form-textarea>
        </b-form-group>
      </div>
      <div class="d-flex flex-row-reverse mb-3" v-if="!isNotFreelance">
        <b-button @click.prevent="onSubmitComponent()" variant="primary"
          >{{ this.mode == "create" ? "Guardar" : "Actualizar" }} y
          continuar</b-button
        >
      </div>

      <!-- alertas -->
      <b-alert v-if="alert.isSaved" show :variant="alert.variant"
        >Se guardaron los registros</b-alert
      >
      <b-alert v-else-if="alert.isSaved != null" show :variant="alert.variant"
        >Ha ocurrido un error al guardar los registros
      </b-alert>
    </b-form>
  </div>
</template>

<style scoped></style>
