<script>
import HelexiumForm from "./HelexiumForm";
import data from "./revisionPeriodicaVehiculo";
import moment_timezone from "moment-timezone";
const Swal = require("sweetalert2");

export default {
  name: "InspeccionVehiculo",
  computed: {
    items() {
      return [
        {
          name: "documentacion",
          title: "Documentación",
        },
        {
          name: "OrdenYAseo",
          title: "Orden y aseo",
        },
        {
          name: "carroceria",
          title: "Carrocería",
        },
        {
          name: "cabina",
          title: "Cabina",
        },
        {
          name: "motor",
          title: "Motor",
        },
        {
          name: "herramientas",
          title: "Herramientas",
        },
        {
          name: "kitAmbiental",
          title: "Kit ambiental",
        },
      ];
    },
    filterRevision() {
      const obj = { ...this.aloneRevision };

      const propiedadesAConservar = ["index", "fechaRegistro"];

      const newObj = {};

      propiedadesAConservar.forEach((propiedad) => {
        newObj[propiedad == "index" ? "inspeccion" : propiedad] =
          obj[propiedad];
      });

      return newObj;
    },
    documentacionRevision() {
      const obj = { ...this.aloneRevision };

      const propiedadesAConservar = data.documentacion.map((item) => item.name);

      const documentacion = {};

      propiedadesAConservar.forEach((propiedad) => {
        documentacion[propiedad] = obj[propiedad];
      });

      return documentacion;
    },
    ordenYAseoRevision() {
      const obj = { ...this.aloneRevision };

      const propiedadesAConservar = data.OrdenYAseo.map((item) => item.name);

      const ordernYAseo = {};

      propiedadesAConservar.forEach((propiedad) => {
        ordernYAseo[propiedad] = obj[propiedad];
      });

      return ordernYAseo;
    },
    carroceriaRevision() {
      const obj = { ...this.aloneRevision };

      const propiedadesAConservar = data.carroceria.map((item) => item.name);

      const carroceria = {};

      propiedadesAConservar.forEach((propiedad) => {
        carroceria[propiedad] = obj[propiedad];
      });

      return carroceria;
    },
    cabinaRevision() {
      const obj = { ...this.aloneRevision };

      const propiedadesAConservar = data.cabina.map((item) => item.name);

      const cabina = {};

      propiedadesAConservar.forEach((propiedad) => {
        cabina[propiedad] = obj[propiedad];
      });

      return cabina;
    },
    motorRevision() {
      const obj = { ...this.aloneRevision };

      const propiedadesAConservar = data.motor.map((item) => item.name);

      const motor = {};

      propiedadesAConservar.forEach((propiedad) => {
        motor[propiedad] = obj[propiedad];
      });

      return motor;
    },
    herramientasRevision() {
      const obj = { ...this.aloneRevision };

      const propiedadesAConservar = data.herramientas.map((item) => item.name);

      const herramientas = {};

      propiedadesAConservar.forEach((propiedad) => {
        herramientas[propiedad] = obj[propiedad];
      });

      return herramientas;
    },
    kitAmbientalRevision() {
      const obj = { ...this.aloneRevision };

      const propiedadesAConservar = data.kitAmbiental.map((item) => item.name);

      const kitAmbiental = {};

      propiedadesAConservar.forEach((propiedad) => {
        kitAmbiental[propiedad] = obj[propiedad];
      });

      return kitAmbiental;
    },
  },
  mounted() {
    this.$store.dispatch("initInspeccionVehiculo");
    // console.log("usuario id", this.$store.getters.userLoggedIn);
  },
  data() {
    return {
      placa: {
        searching: false,
        inputSearchPlaca: null,
        result: null,
        isFound: null,
      },
      excludedProperties: ["id", "vehiculoId"],
      revisionVehiculo: {
        data: [],
        isFound: null,
        isCreate: null,
        isUpdate: null,
        mode: "",
        idRegistro: "",
      },
      alert: {
        message: "",
        variant: "success",
      },
      aloneRevision: [],
      closeDetails: null,
      dataPost: {
        VehiculoId: null,
        FechaRegistro: this.$moment
          .tz(new Date(), "America/Bogota")
          .format("YYYY-MM-DDTHH:mm"),
        FechaFinalizacion: this.$moment
          .tz(new Date(), "America/Bogota")
          .format("YYYY-MM-DDTHH:mm"),
      },
      newRevision: {
        id: "00000000-0000-0000-0000-000000000000",
        vehiculoId: "00000000-0000-0000-0000-000000000000",
        fechaRegistro: "0001-01-01T00:00:00",
        fechaFinalizacion: "0001-01-01T00:00:00",
        kilometraje: 0,
        tipoInspeccion: null,
        tarjetaPropiedad: null,
        seguroObligatorio: null,
        revisionTecnomecanica: null,
        polizaContractual: null,
        observacionesDocumento: null,
        aseoGeneral: null,
        accesoPisosSinObstaculos: null,
        almacenamientoHerramienta: null,
        almacenamientoRepuestos: null,
        cabinaOrdenadaSinObjetosSuelo: null,
        observacionesOrdenAseo: null,
        llantaRepuesto: null,
        estadoLlantas: null,
        rines: null,
        bomperTrasero: null,
        bomperDelantero: null,
        stop: null,
        placa: null,
        guardaPolvos: null,
        emblemas: null,
        manijas: null,
        barraAntivuelco: null,
        cubrePlaton: null,
        vidrios: null,
        espejos: null,
        lameVidrio: null,
        cocuyos: null,
        plumillas: null,
        mionas: null,
        antena: null,
        direccionales: null,
        luces: null,
        ganchosRemolque: null,
        llavero: null,
        observacionesCarroceria: null,
        sillaConductor: null,
        sillaCopiloto: null,
        sillaPasajeros: null,
        forros: null,
        volante: null,
        tableroInstrumentos: null,
        espejoRetrovisor: null,
        reloj: null,
        funcionPito: null,
        tapetes: null,
        manijaFrenoDeMano: null,
        botonesAc: null,
        radio: null,
        controlRadio: null,
        parlantes: null,
        elevavidrios: null,
        switchEspejos: null,
        switchLuces: null,
        viseras: null,
        cinturonesSeguridad: null,
        manijasInternas: null,
        encendedor: null,
        cenicero: null,
        observacionesCabina: null,
        bateria: null,
        filtroTrampa: null,
        posicionCorreas: null,
        bayoneta: null,
        tapas: null,
        conexionesCables: null,
        niveles: null,
        observacionesMotor: null,
        gato: null,
        palancaGato: null,
        conos: null,
        hombreSolo: null,
        alicate: null,
        llavesBocaFija: null,
        cablesInicio: null,
        botiquin: null,
        extintor: null,
        llaveAlemana: null,
        llavePerno: null,
        tacosPlasticos: null,
        calibradorPresion: null,
        lamparaLinterna: null,
        destornilladorMixto: null,
        llavesBristol: null,
        manila12m: null,
        otras: null,
        observacionesHerramientas: null,
        palaAntichispasEscobilla: null,
        telaOleofilicaPanosAbsorbentes: null,
        bolsasRojas: null,
        cintaDeSeguridad: null,
        guantesDeNitrilo: null,
        proteccionRespiratoria: null,
        observacionesElementosSeguridad: null,
      },
      butonLoader: false
    };
  },
  methods: {
    async PostData(data) {
      data.usuarioId = this.$store.getters.userLoggedIn.id;
      try {
        const res = await this.$store.dispatch("post", {
          path: `RevisionPeriodicaVehiculo/`,
          data,
        });

        return res;
      } catch (error) {
        return error;
      }
    },
    async createRevision() {
      if( this.butonLoader) return

      console.log("daaaaaaaaaaaaaaaaaaaa", this.dataPost);
      this.butonLoader = true
      let id = await this.PostData(this.dataPost);
      this.butonLoader = false
      if( id.status == 409 ) {
          Swal.fire({
            title: "Error!",
            text: id.title,
            icon: "error",
            confirmButtonText: "Aceptar",
          })
        return 
      }

      this.butonLoader = false
      this.revisionVehiculo.idRegistro = id;
      this.revisionVehiculo.data = [
        ...this.revisionVehiculo.data,
        {
          ...this.newRevision,
          id: id,
          fechaRegistro: this.$moment
            .tz(new Date(), "America/Bogota")
            .format("YYYY-MM-DDTHH:mm"),
          fechaFinalizacion: this.$moment
            .tz(new Date(), "America/Bogota")
            .format("YYYY-MM-DDTHH:mm"),
          vehiculoId: this.dataPost.VehiculoId,
        },
      ];
      this.revisionVehiculo.isFound = true;
      this.alert.message = "Se encontraron registros para la inspeccion";
      this.alert.variant = "success";

      console.log("revisioooooooooooooooooooon", this.newRevision);

      console.log([
        ...this.revisionVehiculo.data,
        {
          ...this.newRevision,
          id: id,
          fechaRegistro: this.dataPost.FechaRegistro,
          fechaFinalizacion: this.dataPost.FechaFinalizacion,
          vehiculoId: this.dataPost.VehiculoId,
        },
      ]);
    },
    async finalizarJornada(id) {
      console.log(id);
      try {
        Swal.fire({
          title: "Seguro quiere finalizar la jornada?",
          text: "Después de hacerlo no podras revertir los cambios!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si!",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.isConfirmed) {
            const nuevaFechaFinalizacion = this.$moment
              .tz(new Date(), "America/Bogota")
              .format("YYYY-MM-DDTHH:mm");

            // Enviar la solicitud PATCH al servicio de cambio de fecha de finalización
            const response = await this.$store.dispatch("patch", {
              path: `RevisionPeriodicaVehiculo/ChangeFechaFinalizacion/${id}`,
              data: nuevaFechaFinalizacion, // Enviar la nueva fecha de finalización
            });

            if (response) {
              this.revisionVehiculo.data.filter((item) => {
                if (item.id == id) {
                  item.fechaFinalizacion = nuevaFechaFinalizacion;
                }
                return item;
              });
              Swal.fire("Listo!", "Se finalizó la joranada.", "success");
            } else {
              Swal.fire(
                "Error!",
                "Ha ocurrido un error,por favor intenta nuevamente.",
                "error"
              );
            }

            // Manejar la respuesta si es necesario
            console.log(
              "Respuesta del servicio de cambio de fecha de finalización:",
              response
            );
          }
        });
      } catch (error) {
        console.log("Error al finalizar la jornada:", error);
        return null;
      }
    },

    searchingPlaca() {
      this.placa.searching = true;

      this.closeDetails = true;

      this.$store
        .dispatch("get", {
          path: `Vehiculo/Placa/${this.placa.inputSearchPlaca}`,
        })
        .then((result) => {
          return new Promise((resolve, reject) => {
            result = result.traceId ? null : result;
            if (result) {
              resolve(result);
            } else {
              reject("Placa no encontrada");
              this.placa.isFound = false;
              this.alert.message = "Placa del vehículo no encontrada";
              this.revisionVehiculo.isFound = false;
              this.revisionVehiculo.data = [];
              this.revisionVehiculo.isCreate = false;
              this.revisionVehiculo.isUpdate = false;
              this.alert.variant = "danger";
            }
          });
        })
        .then(async (result) => {
          this.placa.result = result;
          this.dataPost.VehiculoId = result.id;
          this.searchingRevisionVehiculoAll();
        })
        .finally(() => {
          this.placa.searching = false;
        });
    },
    searchingRevisionVehiculoAll() {
      this.$store
        .dispatch("get", {
          path: `RevisionPeriodicaVehiculo/All/${this.placa.result.id}`,
        })
        .then((result) => {
          return new Promise((resolve, reject) => {
            if (result.length) {
              resolve(result);
              this.revisionVehiculo.isFound = true;
              this.alert.message =
                "Se encontraron registros para la inspeccion";
              this.revisionVehiculo.isCreate = false;
              this.revisionVehiculo.isUpdate = false;
              this.alert.variant = "success";
            } else {
              reject("revision no encontrada");
              this.revisionVehiculo.isFound = false;
              this.alert.message =
                "La placa existe, pero no se encontraron registros para la inspeccion";
              this.alert.variant = "info";
              this.revisionVehiculo.data = [];
              this.revisionVehiculo.isCreate = false;
              this.revisionVehiculo.isUpdate = false;
            }
          });
        })
        .then(async (result) => {
          this.revisionVehiculo.data = result;
        })
        .finally(() => {});
    },
    searchingRevisionVehiculoAlone(id) {
      this.$store
        .dispatch("get", {
          path: `RevisionPeriodicaVehiculo/Alone/${id}`,
        })
        .then((result) => {
          return new Promise((resolve, reject) => {
            if (result.length) {
              resolve(result);
            } else {
              reject("revision no encontrada");
            }
          });
        })
        .then(async (result) => {
          this.revisionVehiculo.data = result;
          console.log(this.revisionVehiculo.data);
        });
    },
    async revisionfilterById(id, index) {
      let result = this.revisionVehiculo.data.filter(
        (revison) => revison.id === id
      );

      this.revisionVehiculo.idRegistro = id;
      this.closeDetails = false;
      this.revisionVehiculo.isCreate = false;
      this.revisionVehiculo.isUpdate = false;
      result[0].index = index;
      this.aloneRevision = result[0];
    },
  },
  filters: {
    timeSince(dateString, timezone = "America/Bogota") {
      const dateInBogota = moment_timezone.tz(dateString, timezone);
      return dateInBogota.format("YYYY-MM-DD hh:mm A");
    },
  },

  components: {
    HelexiumForm,
  },
};
</script>

<template>
  <!-- Una tabla relacionado a un vehiculo -->

  <!-- Tipo preoperativa y rutinaria -->
  <!-- guardar fecha -->

  <b-container fluid>
    <b-row class="justify-content-lg-center">
      <b-col>
        <!-- Busquedad por placa vehiculo -->
        <b-card>
          <div class="card-body">
            <h4 class="card-title text-white mb-3">
              <b>Búsquedad por placa vehículo</b>
            </h4>
            <b-form @submit.prevent="searchingPlaca">
              <b-form-group
                label-for="inputSearchPlaca"
                label="Placa vehículo"
                invalid-feedback="Placa no encontrada"
              >
                <b-form-input
                  id="inputSearchPlaca"
                  v-model="placa.inputSearchPlaca"
                  type="text"
                  placeholder="Ingresa la placa del vehículo"
                  required
                ></b-form-input>
              </b-form-group>
              <vs-button
                class="mb-3"
                type="submit"
                :disabled="placa.searching"
                ><i class="fas fa-search pr-2"></i> Buscar</vs-button
              >
            </b-form>

            <!-- alertas -->
            <b-alert
              v-if="placa.isFound || revisionVehiculo.isFound"
              show
               class="text-white"
              :variant="alert.variant"
              >{{ alert.message }}</b-alert
            >
            <b-alert
              v-else-if="
                placa.isFound != null || revisionVehiculo.isFound != null
              "
              show
              class="text-white"
              :variant="alert.variant"
              >{{ alert.message }}
              <strong
                v-if="
                  revisionVehiculo.isFound != null && alert.variant != 'danger'
                "
                class="ml-3 pointer"
                @click="
                  () => {
                    this.createRevision();
                    revisionVehiculo.isCreate = true;
                    revisionVehiculo.mode = 'create';
                  }
                "
                >Click aquí para crear.</strong
              >
            </b-alert>

            <!-- informacion de inspecciones -->
            <article v-if="revisionVehiculo.data.length" class="mt-4">
              <h4 class="my-3">Información de las inspecciones</h4>
              <div class="flex-column align-items-start">
                <div class="d-flex w-100 justify-content-between">
                  <h4 class="mb-1">
                    Placa del vehículo: {{ placa.result.placa }}
                  </h4>
                </div>
                <div class="d-flex flex-wrap align-items-center">
                  <strong class="mr-2"
                    >Listado de registros de inspeccion</strong
                  >
                  <span
                    @click="
                      () => {
                        this.createRevision();
                        closeDetails = true;
                        revisionVehiculo.isCreate = true;
                        revisionVehiculo.mode = 'create';
                      }
                    "
                    class="my-2 btn-outline-info btn"
                    >Crear Nueva</span
                  >
                </div>

                <!-- listado de inspecciones -->
                <div
                  class="d-flex flex-wrap justify-content-center justify-content-lg-start"
                >
                  <div
                    class="btn"
                    v-for="(revision, index) in revisionVehiculo.data"
                    :key="index"
                    @click="revisionfilterById(revision.id, index)"
                  >
                    <b-card
                      class="position-relative"
                      style="
                        border: 1px solid white;
                        min-height: 100px;
                        max-height: 200px;
                      "
                    >
                      <span
                        class="bg-blue rounded position-absolute"
                        style="
                          top: -10px;
                          left: -10px;
                          width: 20px;
                          height: 20px;
                          border-radius: 50%;
                        "
                      >
                        {{ index + 1 }}
                      </span>
                      <!-- icons -->
                      <div class="d-flex flex-wrap" style="gap: 10px">
                        <div
                          class="d-flex align-items-center pl-2"
                          style="border-left: 2px solid #df122e"
                        >
                          <i
                            class="fas fa-calendar-plus text-white"
                            style="font-size: 1.2rem"
                          ></i>
                          <div class="d-flex flex-column">
                            <span class="text-white-50">Fecha creación</span>
                            <span class="text-white">{{
                              revision.fechaRegistro | timeSince
                            }}</span>
                          </div>
                        </div>

                        <div
                          v-if="
                            revision.fechaRegistro !==
                            revision.fechaFinalizacion
                          "
                          class="d-flex align-items-center pl-2"
                        >
                          <i
                            class="fas fa-calendar-check text-white"
                            style="font-size: 1.2rem"
                          ></i>
                          <div class="d-flex flex-column">
                            <span class="text-white-50"
                              >Fecha finalización</span
                            >
                            <span class="text-white">{{
                              revision.fechaFinalizacion | timeSince
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div
                        class="d-flex justify-content-end align-items-center"
                      >
                        <b-button
                          v-if="
                            revision.fechaRegistro ===
                            revision.fechaFinalizacion
                          "
                          class="mt-1"
                          size="sm"
                          @click="finalizarJornada(revision.id)"
                        >
                          Finalizar jornada
                        </b-button>
                        <span
                          class="mt-2 text-success fw-bold"
                          v-if="
                            revision.fechaRegistro !==
                            revision.fechaFinalizacion
                          "
                        >
                          Jornada finalizada
                        </span>
                      </div>
                    </b-card>
                  </div>
                </div>

                <!-- detalle unico  de cada revision -->
                <div v-if="aloneRevision && closeDetails == false">
                  <div>
                    <article>
                      <div class="d-flex justify-content-between">
                        <span
                          @click="
                            () => {
                              closeDetails = true;
                              revisionVehiculo.isUpdate = true;
                              revisionVehiculo.mode = 'update';
                            }
                          "
                          class="my-2 btn-outline-success btn"
                          >Editar inspeccion</span
                        >
                        <span
                          @click="
                            () => {
                              closeDetails = true;
                            }
                          "
                          class="my-2 btn text-danger"
                          >Cerrar</span
                        >
                      </div>
                      <div>
                        <!-- fecha registro y tipo inspeccion -->
                        <div class="mb-2">
                          <div class="mt-2">
                            <div
                              v-for="(value, key) in filterRevision"
                              :key="key"
                            >
                              <p class="text-capitalize">
                                <strong>{{ key }}:</strong>

                                <span
                                  v-if="key == 'inspeccion'"
                                  class="py-1 px-2 ml-1 border rounded"
                                >
                                  {{ value + 1 }}
                                </span>
                                <span
                                  v-else
                                  class="py-1 px-2 ml-1 border rounded"
                                >
                                  {{ value | timeSince }}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <!-- documentacion -->
                        <div class="mb-2">
                          <div>
                            <div>
                              <h3>Documentacion</h3>
                              <hr />
                            </div>
                            <div class="row">
                              <div
                                class="col-12 col-lg-4"
                                v-for="(value, key) in documentacionRevision"
                                :key="key"
                              >
                                <p
                                  :class="
                                    key.includes('observaciones')
                                      ? 'text-capitalize d-flex flex-column'
                                      : 'text-capitalize'
                                  "
                                >
                                  <strong
                                    >{{
                                      key.includes("observaciones")
                                        ? "observaciones"
                                        : key
                                    }}:</strong
                                  >
                                  <span
                                    style="word-wrap: break-word; margin: 5px"
                                    class="py-1 px-2 ml-1 border rounded"
                                  >
                                    {{ value ?? "sin registros" }}</span
                                  >
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- orden y aseo -->
                        <div class="mb-2">
                          <div>
                            <h3>Orden y Aseo</h3>
                            <hr />
                          </div>
                          <div class="row">
                            <div
                              class="col-12 col-lg-4"
                              v-for="(value, key) in ordenYAseoRevision"
                              :key="key"
                            >
                              <p
                                :class="
                                  key.includes('observaciones')
                                    ? 'text-capitalize d-flex flex-column'
                                    : 'text-capitalize'
                                "
                              >
                                <strong
                                  >{{
                                    key.includes("observaciones")
                                      ? "observaciones"
                                      : key
                                  }}:</strong
                                >
                                <span
                                  style="word-wrap: break-word"
                                  class="py-1 px-2 ml-1 border rounded"
                                >
                                  {{ value ?? "sin registros" }}</span
                                >
                              </p>
                            </div>
                          </div>
                        </div>
                        <!-- carroceria -->
                        <div class="mb-2">
                          <div>
                            <h3>Carroceria</h3>
                            <hr />
                          </div>
                          <div class="row">
                            <div
                              class="col-12 col-lg-4"
                              v-for="(value, key) in carroceriaRevision"
                              :key="key"
                            >
                              <p
                                :class="
                                  key.includes('observaciones')
                                    ? 'text-capitalize d-flex flex-column'
                                    : 'text-capitalize'
                                "
                              >
                                <strong
                                  >{{
                                    key.includes("observaciones")
                                      ? "observaciones"
                                      : key
                                  }}:</strong
                                >
                                <span
                                  style="word-wrap: break-word"
                                  class="py-1 px-2 ml-1 border rounded"
                                >
                                  {{ value ?? "sin registros" }}</span
                                >
                              </p>
                            </div>
                          </div>
                        </div>
                        <!-- cabina -->
                        <div class="mb-2">
                          <div>
                            <h3>Cabina</h3>
                            <hr />
                          </div>
                          <div class="row">
                            <div
                              class="col-12 col-lg-4"
                              v-for="(value, key) in cabinaRevision"
                              :key="key"
                            >
                              <p
                                :class="
                                  key.includes('observaciones')
                                    ? 'text-capitalize d-flex flex-column'
                                    : 'text-capitalize'
                                "
                              >
                                <strong
                                  >{{
                                    key.includes("observaciones")
                                      ? "observaciones"
                                      : key
                                  }}:</strong
                                >
                                <span
                                  style="word-wrap: break-word"
                                  class="py-1 px-2 ml-1 border rounded"
                                >
                                  {{ value ?? "sin registros" }}</span
                                >
                              </p>
                            </div>
                          </div>
                        </div>
                        <!-- motor -->
                        <div class="mb-2">
                          <div>
                            <h3>Motor</h3>
                            <hr />
                          </div>
                          <div class="row">
                            <div
                              class="col-12 col-lg-4"
                              v-for="(value, key) in motorRevision"
                              :key="key"
                            >
                              <p
                                :class="
                                  key.includes('observaciones')
                                    ? 'text-capitalize d-flex flex-column'
                                    : 'text-capitalize'
                                "
                              >
                                <strong
                                  >{{
                                    key.includes("observaciones")
                                      ? "observaciones"
                                      : key
                                  }}:</strong
                                >
                                <span
                                  style="word-wrap: break-word"
                                  class="py-1 px-2 ml-1 border rounded"
                                >
                                  {{ value ?? "sin registros" }}</span
                                >
                              </p>
                            </div>
                          </div>
                        </div>
                        <!-- herramientas -->
                        <div class="mb-2">
                          <div>
                            <h3>Herramientas</h3>
                            <hr />
                          </div>
                          <div class="row">
                            <div
                              class="col-12 col-lg-4"
                              v-for="(value, key) in herramientasRevision"
                              :key="key"
                            >
                              <p
                                :class="
                                  key.includes('observaciones')
                                    ? 'text-capitalize d-flex flex-column'
                                    : 'text-capitalize'
                                "
                              >
                                <strong
                                  >{{
                                    key.includes("observaciones")
                                      ? "observaciones"
                                      : key
                                  }}:</strong
                                >
                                <span
                                  style="word-wrap: break-word"
                                  class="py-1 px-2 ml-1 border rounded"
                                >
                                  {{ value ?? "sin registros" }}</span
                                >
                              </p>
                            </div>
                          </div>
                        </div>
                        <!-- kit ambiental -->
                        <div class="mb-2">
                          <div>
                            <h3>Kit ambiental</h3>
                            <hr />
                          </div>
                          <div class="row">
                            <div
                              class="col-12 col-lg-4"
                              v-for="(value, key) in kitAmbientalRevision"
                              :key="key"
                            >
                              <p
                                :class="
                                  key.includes('observaciones')
                                    ? 'text-capitalize d-flex flex-column'
                                    : 'text-capitalize'
                                "
                              >
                                <strong
                                  >{{
                                    key.includes("observaciones")
                                      ? "observaciones"
                                      : key
                                  }}:</strong
                                >
                                <span
                                  style="word-wrap: break-word"
                                  class="py-1 px-2 ml-1 border rounded"
                                >
                                  {{ value ?? "sin registros" }}</span
                                >
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </b-card>

        <!-- inspeccion vehiculo -->
        <b-card
          v-if="revisionVehiculo.isCreate || revisionVehiculo.isUpdate"
          class="py-4 px-4"
        >
          <h2>Inspección vehículo</h2>
          <hr />
          <div v-for="item in items" :key="item.name">
            <h3>{{ item.title }}</h3>
            <HelexiumForm
              :items="items"
              storeStateComponent="inspeccionVehiculo"
              :currencyModel="item.name"
              :mode="revisionVehiculo.mode"
              :idRegistro="revisionVehiculo.idRegistro"
              :data="aloneRevision"
              :revisionPeriodicaVehiculo="revisionVehiculo.data"
            />
            <hr />
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
