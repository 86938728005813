var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',{staticClass:"justify-content-lg-center"},[_c('b-col',[_c('b-card',[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title text-white mb-3"},[_c('b',[_vm._v("Búsquedad por placa vehículo")])]),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.searchingPlaca.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label-for":"inputSearchPlaca","label":"Placa vehículo","invalid-feedback":"Placa no encontrada"}},[_c('b-form-input',{attrs:{"id":"inputSearchPlaca","type":"text","placeholder":"Ingresa la placa del vehículo","required":""},model:{value:(_vm.placa.inputSearchPlaca),callback:function ($$v) {_vm.$set(_vm.placa, "inputSearchPlaca", $$v)},expression:"placa.inputSearchPlaca"}})],1),_c('vs-button',{staticClass:"mb-3",attrs:{"type":"submit","disabled":_vm.placa.searching}},[_c('i',{staticClass:"fas fa-search pr-2"}),_vm._v(" Buscar")])],1),(_vm.placa.isFound || _vm.revisionVehiculo.isFound)?_c('b-alert',{staticClass:"text-white",attrs:{"show":"","variant":_vm.alert.variant}},[_vm._v(_vm._s(_vm.alert.message))]):(
              _vm.placa.isFound != null || _vm.revisionVehiculo.isFound != null
            )?_c('b-alert',{staticClass:"text-white",attrs:{"show":"","variant":_vm.alert.variant}},[_vm._v(_vm._s(_vm.alert.message)+" "),(
                _vm.revisionVehiculo.isFound != null && _vm.alert.variant != 'danger'
              )?_c('strong',{staticClass:"ml-3 pointer",on:{"click":() => {
                  this.createRevision();
                  _vm.revisionVehiculo.isCreate = true;
                  _vm.revisionVehiculo.mode = 'create';
                }}},[_vm._v("Click aquí para crear.")]):_vm._e()]):_vm._e(),(_vm.revisionVehiculo.data.length)?_c('article',{staticClass:"mt-4"},[_c('h4',{staticClass:"my-3"},[_vm._v("Información de las inspecciones")]),_c('div',{staticClass:"flex-column align-items-start"},[_c('div',{staticClass:"d-flex w-100 justify-content-between"},[_c('h4',{staticClass:"mb-1"},[_vm._v(" Placa del vehículo: "+_vm._s(_vm.placa.result.placa)+" ")])]),_c('div',{staticClass:"d-flex flex-wrap align-items-center"},[_c('strong',{staticClass:"mr-2"},[_vm._v("Listado de registros de inspeccion")]),_c('span',{staticClass:"my-2 btn-outline-info btn",on:{"click":() => {
                      this.createRevision();
                      _vm.closeDetails = true;
                      _vm.revisionVehiculo.isCreate = true;
                      _vm.revisionVehiculo.mode = 'create';
                    }}},[_vm._v("Crear Nueva")])]),_c('div',{staticClass:"d-flex flex-wrap justify-content-center justify-content-lg-start"},_vm._l((_vm.revisionVehiculo.data),function(revision,index){return _c('div',{key:index,staticClass:"btn",on:{"click":function($event){return _vm.revisionfilterById(revision.id, index)}}},[_c('b-card',{staticClass:"position-relative",staticStyle:{"border":"1px solid white","min-height":"100px","max-height":"200px"}},[_c('span',{staticClass:"bg-blue rounded position-absolute",staticStyle:{"top":"-10px","left":"-10px","width":"20px","height":"20px","border-radius":"50%"}},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('div',{staticClass:"d-flex flex-wrap",staticStyle:{"gap":"10px"}},[_c('div',{staticClass:"d-flex align-items-center pl-2",staticStyle:{"border-left":"2px solid #df122e"}},[_c('i',{staticClass:"fas fa-calendar-plus text-white",staticStyle:{"font-size":"1.2rem"}}),_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"text-white-50"},[_vm._v("Fecha creación")]),_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(_vm._f("timeSince")(revision.fechaRegistro)))])])]),(
                          revision.fechaRegistro !==
                          revision.fechaFinalizacion
                        )?_c('div',{staticClass:"d-flex align-items-center pl-2"},[_c('i',{staticClass:"fas fa-calendar-check text-white",staticStyle:{"font-size":"1.2rem"}}),_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"text-white-50"},[_vm._v("Fecha finalización")]),_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(_vm._f("timeSince")(revision.fechaFinalizacion)))])])]):_vm._e()]),_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[(
                          revision.fechaRegistro ===
                          revision.fechaFinalizacion
                        )?_c('b-button',{staticClass:"mt-1",attrs:{"size":"sm"},on:{"click":function($event){return _vm.finalizarJornada(revision.id)}}},[_vm._v(" Finalizar jornada ")]):_vm._e(),(
                          revision.fechaRegistro !==
                          revision.fechaFinalizacion
                        )?_c('span',{staticClass:"mt-2 text-success fw-bold"},[_vm._v(" Jornada finalizada ")]):_vm._e()],1)])],1)}),0),(_vm.aloneRevision && _vm.closeDetails == false)?_c('div',[_c('div',[_c('article',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',{staticClass:"my-2 btn-outline-success btn",on:{"click":() => {
                            _vm.closeDetails = true;
                            _vm.revisionVehiculo.isUpdate = true;
                            _vm.revisionVehiculo.mode = 'update';
                          }}},[_vm._v("Editar inspeccion")]),_c('span',{staticClass:"my-2 btn text-danger",on:{"click":() => {
                            _vm.closeDetails = true;
                          }}},[_vm._v("Cerrar")])]),_c('div',[_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"mt-2"},_vm._l((_vm.filterRevision),function(value,key){return _c('div',{key:key},[_c('p',{staticClass:"text-capitalize"},[_c('strong',[_vm._v(_vm._s(key)+":")]),(key == 'inspeccion')?_c('span',{staticClass:"py-1 px-2 ml-1 border rounded"},[_vm._v(" "+_vm._s(value + 1)+" ")]):_c('span',{staticClass:"py-1 px-2 ml-1 border rounded"},[_vm._v(" "+_vm._s(_vm._f("timeSince")(value))+" ")])])])}),0)]),_c('div',{staticClass:"mb-2"},[_c('div',[_c('div',[_c('h3',[_vm._v("Documentacion")]),_c('hr')]),_c('div',{staticClass:"row"},_vm._l((_vm.documentacionRevision),function(value,key){return _c('div',{key:key,staticClass:"col-12 col-lg-4"},[_c('p',{class:key.includes('observaciones')
                                    ? 'text-capitalize d-flex flex-column'
                                    : 'text-capitalize'},[_c('strong',[_vm._v(_vm._s(key.includes("observaciones") ? "observaciones" : key)+":")]),_c('span',{staticClass:"py-1 px-2 ml-1 border rounded",staticStyle:{"word-wrap":"break-word","margin":"5px"}},[_vm._v(" "+_vm._s(value ?? "sin registros"))])])])}),0)])]),_c('div',{staticClass:"mb-2"},[_c('div',[_c('h3',[_vm._v("Orden y Aseo")]),_c('hr')]),_c('div',{staticClass:"row"},_vm._l((_vm.ordenYAseoRevision),function(value,key){return _c('div',{key:key,staticClass:"col-12 col-lg-4"},[_c('p',{class:key.includes('observaciones')
                                  ? 'text-capitalize d-flex flex-column'
                                  : 'text-capitalize'},[_c('strong',[_vm._v(_vm._s(key.includes("observaciones") ? "observaciones" : key)+":")]),_c('span',{staticClass:"py-1 px-2 ml-1 border rounded",staticStyle:{"word-wrap":"break-word"}},[_vm._v(" "+_vm._s(value ?? "sin registros"))])])])}),0)]),_c('div',{staticClass:"mb-2"},[_c('div',[_c('h3',[_vm._v("Carroceria")]),_c('hr')]),_c('div',{staticClass:"row"},_vm._l((_vm.carroceriaRevision),function(value,key){return _c('div',{key:key,staticClass:"col-12 col-lg-4"},[_c('p',{class:key.includes('observaciones')
                                  ? 'text-capitalize d-flex flex-column'
                                  : 'text-capitalize'},[_c('strong',[_vm._v(_vm._s(key.includes("observaciones") ? "observaciones" : key)+":")]),_c('span',{staticClass:"py-1 px-2 ml-1 border rounded",staticStyle:{"word-wrap":"break-word"}},[_vm._v(" "+_vm._s(value ?? "sin registros"))])])])}),0)]),_c('div',{staticClass:"mb-2"},[_c('div',[_c('h3',[_vm._v("Cabina")]),_c('hr')]),_c('div',{staticClass:"row"},_vm._l((_vm.cabinaRevision),function(value,key){return _c('div',{key:key,staticClass:"col-12 col-lg-4"},[_c('p',{class:key.includes('observaciones')
                                  ? 'text-capitalize d-flex flex-column'
                                  : 'text-capitalize'},[_c('strong',[_vm._v(_vm._s(key.includes("observaciones") ? "observaciones" : key)+":")]),_c('span',{staticClass:"py-1 px-2 ml-1 border rounded",staticStyle:{"word-wrap":"break-word"}},[_vm._v(" "+_vm._s(value ?? "sin registros"))])])])}),0)]),_c('div',{staticClass:"mb-2"},[_c('div',[_c('h3',[_vm._v("Motor")]),_c('hr')]),_c('div',{staticClass:"row"},_vm._l((_vm.motorRevision),function(value,key){return _c('div',{key:key,staticClass:"col-12 col-lg-4"},[_c('p',{class:key.includes('observaciones')
                                  ? 'text-capitalize d-flex flex-column'
                                  : 'text-capitalize'},[_c('strong',[_vm._v(_vm._s(key.includes("observaciones") ? "observaciones" : key)+":")]),_c('span',{staticClass:"py-1 px-2 ml-1 border rounded",staticStyle:{"word-wrap":"break-word"}},[_vm._v(" "+_vm._s(value ?? "sin registros"))])])])}),0)]),_c('div',{staticClass:"mb-2"},[_c('div',[_c('h3',[_vm._v("Herramientas")]),_c('hr')]),_c('div',{staticClass:"row"},_vm._l((_vm.herramientasRevision),function(value,key){return _c('div',{key:key,staticClass:"col-12 col-lg-4"},[_c('p',{class:key.includes('observaciones')
                                  ? 'text-capitalize d-flex flex-column'
                                  : 'text-capitalize'},[_c('strong',[_vm._v(_vm._s(key.includes("observaciones") ? "observaciones" : key)+":")]),_c('span',{staticClass:"py-1 px-2 ml-1 border rounded",staticStyle:{"word-wrap":"break-word"}},[_vm._v(" "+_vm._s(value ?? "sin registros"))])])])}),0)]),_c('div',{staticClass:"mb-2"},[_c('div',[_c('h3',[_vm._v("Kit ambiental")]),_c('hr')]),_c('div',{staticClass:"row"},_vm._l((_vm.kitAmbientalRevision),function(value,key){return _c('div',{key:key,staticClass:"col-12 col-lg-4"},[_c('p',{class:key.includes('observaciones')
                                  ? 'text-capitalize d-flex flex-column'
                                  : 'text-capitalize'},[_c('strong',[_vm._v(_vm._s(key.includes("observaciones") ? "observaciones" : key)+":")]),_c('span',{staticClass:"py-1 px-2 ml-1 border rounded",staticStyle:{"word-wrap":"break-word"}},[_vm._v(" "+_vm._s(value ?? "sin registros"))])])])}),0)])])])])]):_vm._e()])]):_vm._e()],1)]),(_vm.revisionVehiculo.isCreate || _vm.revisionVehiculo.isUpdate)?_c('b-card',{staticClass:"py-4 px-4"},[_c('h2',[_vm._v("Inspección vehículo")]),_c('hr'),_vm._l((_vm.items),function(item){return _c('div',{key:item.name},[_c('h3',[_vm._v(_vm._s(item.title))]),_c('HelexiumForm',{attrs:{"items":_vm.items,"storeStateComponent":"inspeccionVehiculo","currencyModel":item.name,"mode":_vm.revisionVehiculo.mode,"idRegistro":_vm.revisionVehiculo.idRegistro,"data":_vm.aloneRevision,"revisionPeriodicaVehiculo":_vm.revisionVehiculo.data}}),_c('hr')],1)})],2):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }